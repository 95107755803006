<!--
 * @Author: wang peng
 * @Description: 社区私桩列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        :selectable="true"
        :operateButtonsSplitCount="5"
        :rowSelectable="row => true"
        :selectOnIndeterminate="false"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button
                v-if="i != 0 && btn.enabled()"
                :key="i"
                :type="btn.type"
                @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
              <el-dropdown @command="handleCommand" v-if="i == 0 && btn.enabled()" :key="i">
                <ykc-button :type="btn.type" v-if="btn.enabled()">
                  {{ btn.text }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </ykc-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item
                    :command="'levelConfig'"
                    v-if="code('asset:station:diretpile:batchAdd')">
                    批量新增
                  </el-dropdown-item> -->
                  <el-dropdown-item
                    :command="'growthValueRule'"
                    v-if="code('asset:station:privatepile:batchTiming')">
                    校时/校价
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="'publishQrCode'"
                    v-if="code('asset:station:privatepile:publishQrCode')">
                    二维码下发
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="'exportQrCode'"
                    v-if="code('asset:station:privatepile:exportQrCode')">
                    二维码导出
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!-- 新增/编辑抽屉 -->
      <ykc-drawer
        :title="drawerTitle"
        :show.sync="showDrawer"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
          <AddOrEdit
            v-if="drawerType === 'add' || drawerType === 'edit' || drawerType === 'detail'"
            ref="addOrEdit"
            :drawerType="drawerType"
            :gunId="gunId"
            :isEdit="isEdit"
            :editInfo="editInfo"></AddOrEdit>
        </div>
      </ykc-drawer>
      <!--自定义列弹窗 -->
      <ykc-operation-dialog
        title="自定义列"
        show-footer
        :show.sync="show1"
        :before-close="onCLose"
        :before-cancel="beforeCancel"
        :before-confirm="beforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            label-width="80px"
            ref="customForm"
            class="customForm"
            :model="customForm"
            :rules="customRules">
            <el-form-item label="列选项" prop="checkOption">
              <ykc-checkbox
                :configSet="{
                  label: 'label',
                  value: 'value',
                }"
                :data="customData"
                v-model="customForm.checkOption"
                @change="checkboxChange"></ykc-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
      <ykc-operation-dialog
        title="迁桩"
        :before-close="onCLose"
        :show.sync="showDialog"
        :before-confirm="updateStation">
        <div slot="content">
          <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <ykc-form-item label="站点名称" prop="stationId">
              <ykc-dropdown
                :clearable="true"
                :data="brandListData"
                v-model="ruleForm.stationId"></ykc-dropdown>
            </ykc-form-item>
            <ykc-form-item label="车位号" prop="parkNum">
              <ykc-input v-model="ruleForm.parkNum" placeholder="请输入车位号"></ykc-input>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-operation-dialog>
      <ykc-operation-dialog
        title="换桩"
        :show.sync="showchangeDialog"
        :before-confirm="stationChange">
        <div slot="content">
          <el-row>
            <el-col :span="24">老桩信息：</el-col>
            <el-col :offset="4" :span="18">
              <div>建桩联系人手机号：{{ editInfo?.stationPileContractVO?.pilePhone }}</div>
              <div>桩编码：{{ editInfo?.gunCode }}</div>
            </el-col>
            <el-col :span="24">新桩信息：</el-col>
            <el-col :offset="4" :span="18">
              <ykc-form :model="changeForm" :rules="rules" ref="changeForm">
                <ykc-form-item label="桩编码" prop="pileId">
                  <ykc-dropdown
                    :clearable="true"
                    :data="gunCodeData"
                    v-model="changeForm.pileId"></ykc-dropdown>
                </ykc-form-item>
              </ykc-form>
            </el-col>
          </el-row>
        </div>
      </ykc-operation-dialog>
      <ykc-operation-dialog title="二维码" customerWidth="350px" :show.sync="showCode">
        <div slot="content">
          <el-image style="width: 300px; height: 300px" :src="qrcodeImg"></el-image>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import QRCode from 'qrcode';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { barrierPage, directPage, brandModelPage, omManagementApi } from '@/service/apis';
  import { code, localSetItem, localGetItem, offlineExport } from '@/utils';
  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';

  export default {
    name: 'privatePileList',
    components: {
      AddOrEdit,
      ImportData,
    },
    inject: ['reload'],
    data() {
      return {
        qrcodeImg: '',
        showCode: false,
        gunId: '',
        editInfo: {},
        parName: '',
        parCode: '',
        gunCodeData: [],
        maintainDate: {
          startTime: null,
          endTime: null,
        },
        changeForm: {
          pileId: '',
        },
        ruleForm: {
          stationId: '',
          parkNum: '',
        },
        rules: {
          stationId: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          parkNum: [{ required: true, message: '请输入车位号', trigger: 'blur' }],
          pileId: [{ required: true, message: '请选择桩编码', trigger: 'blur' }],
        },
        showDialog: false,

        drawerType: '',
        show1: false,
        isEdit: false,
        showDrawer: false,
        showchangeDialog: false,
        selectedRows: [], // 选中数据行
        brandListData: [], // 归属电站数据
        OperatorListData: [], // 归属商户数据
        brandAndModelData: [], // 终端型号数据
        searchParams: {
          code: '',
          name: '',
          stationId: null,
          stationOperator: null,
          statusList: [],
          startStatus: '',
          brandId: '',
          markStatus: '',
        },
        pageButtons: [
          {
            id: '0',
            text: '批量操作',
            type: 'plain',
            enabled: () => true,
            // code('asset:station:privatepile:batchTiming') ||
            // code('asset:station:privatepile:publishQrCode') ||
            // code('asset:station:privatepile:exportQrCode'),
            handleClick: () => {
              // this.add();
            },
          },

          {
            id: '1',
            text: '自定义列',
            type: 'plain',
            enabled: () => code('asset:station:privatepile:custom'),
            // enabled: () => true,
            handleClick: () => {
              this.customColumns();
            },
          },
          {
            id: '2',
            text: '新增',
            type: 'plain',
            enabled: () => code('asset:station:privatepile:add'),
            // enabled: () => true,
            handleClick: () => {
              this.add();
            },
          },

          {
            id: '3',
            text: '导出',
            type: 'plain',
            enabled: () => code('asset:station:privatepile:export'),
            // enabled: () => true,
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        customForm: {
          checkOption: [0, 1, 2, 3, 4, 5, 6, 12, 13],
        },
        customData: [
          {
            value: 0,
            label: '终端编码',
          },
          {
            value: 1,
            label: '终端名称',
          },
          {
            value: 12,
            label: '车位号',
          },
          {
            value: 3,
            label: '终端启停',
          },
          {
            value: 4,
            label: '工作状态',
          },
          {
            value: 5,
            label: '归属商户',
          },
          {
            value: 6,
            label: '品牌',
          },
          {
            value: 13,
            label: '建桩联系人手机号',
          },
          {
            value: 7,
            label: '维保时间',
          },
          {
            value: 8,
            label: '投运时间',
          },
          {
            value: 9,
            label: '终端型号',
          },
          {
            value: 10,
            label: '型号国标',
          },
          {
            value: 11,
            label: '二维码下发',
          },
        ],
        customRules: {
          checkOption: [{ required: true, message: '请至少选择一个列名称', trigger: 'blur' }],
        },
        tableTitle: '终端列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '终端编码', prop: 'gunCode', minWidth: '200px', id: 0 },
          {
            label: '终端名称',
            prop: 'gunName',
            minWidth: '250px',
            id: 1,
            title: false,
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.gunName} placement="top-start">
            //         <span>{row.gunName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '归属电站',
            prop: 'stationName',
            minWidth: '250px',
            id: 2,
            title: false,
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.stationName} placement="top-start">
            //         <span>{row.stationName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '车位号',
            prop: 'parkNum',
            minWidth: '250px',
            id: 12,
            title: false,
          },
          {
            label: '终端启停',
            prop: 'startStatus',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return row.startStatus === 1 ? (
                  <span class={['userStatus', 'status-blue']}>启用</span>
                ) : (
                  <span class={['userStatus', 'status-red']}>停用</span>
                );
              },
            },
            id: 3,
          },
          {
            label: '工作状态',
            prop: 'gunStatus',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = [
                  {
                    color: 'status-black',
                    name: '离线',
                  },
                  {
                    color: 'status-red',
                    name: '故障',
                  },
                  {
                    color: 'status-green',
                    name: '空闲',
                  },
                  {
                    color: 'status-blue',
                    name: '充电',
                  },
                ][row.gunStatus];

                if (!cls) {
                  return '一';
                }

                return <span class={['userStatus', cls.color]}>{cls.name}</span>;
              },
            },
            id: 4,
          },
          { label: '归属商户', prop: 'operatorName', minWidth: '200px', id: 5 },
          {
            label: '建桩联系人手机号',
            prop: 'stationPileContractVO',
            minWidth: '200px',
            id: 13,
            scopedSlots: {
              default: ({ row }) => {
                return row.stationPileContractVO.pilePhone;
              },
            },
          },
          { label: '品牌', prop: 'brandName', minWidth: '200px', id: 6 },
          { label: '维保到期', prop: 'maintenanceEndDate', minWidth: '200px', id: 7 },
          { label: '投运时间', prop: 'userTime', minWidth: '200px', id: 8 },
          { label: '终端型号', prop: 'brandModelName', minWidth: '200px', id: 9 },
          {
            label: '型号国标',
            prop: 'nationalStandard',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{row.nationalStandard === 1 ? '国标2011' : '国标2015'}</span>;
              },
            },
            id: 10,
          },
          {
            label: '二维码下发',
            prop: 'markStatus',
            scopedSlots: {
              default: ({ row }) => {
                // 0：未下发；1：下发成功；2：下发中；3：下发失败'
                const cls = [
                  {
                    color: 'status-black',
                    name: '未下发',
                  },
                  {
                    color: 'status-green',
                    name: '下发成功',
                  },
                  {
                    color: 'status-blue',
                    name: '下发中',
                  },
                  {
                    color: 'status-red',
                    name: '下发失败',
                  },
                ][row.markStatus];

                if (!cls) {
                  return '一';
                }
                return <span class={['userStatus', cls.color]}>{cls.name}</span>;
              },
            },
            id: 11,
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return this.drawerType !== 'detail';
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '确定',
            enabled: () => {
              return this.drawerType === 'detail';
            },
            handler: () => {
              this.showDrawer = false;
            },
          },
          {
            label: '完成',
            enabled: () => {
              return this.isEdit && this.drawerType !== 'detail';
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return !this.isEdit;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestList();
              });
            },
          },
        ],
      };
    },
    created() {
      this.stationId = Number(this.$route.query.stationId);

      if (this.stationId) {
        this.searchParams.stationId = this.stationId;
      }

      const customForm = localGetItem('privateCustomForm');

      if (customForm) {
        this.customForm.checkOption = customForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return customForm.includes(item.id);
        });
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![7, 8, 9, 10, 11].includes(item.id);
        });
      }
      console.log(' this.tableColumns', this.tableColumns);
      this.requestBrandList();
      this.requestOperatorList();
      this.requestPileCodeList();
      this.requestBrandAndModelList();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'code',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcInput',
            key: 'name',
            label: '终端名称',
            placeholder: '请输入终端名称',
          },
          {
            comName: 'YkcInput',
            key: 'pilePhone',
            label: '建桩联系人手机号',
            placeholder: '请输入建桩联系人手机号',
          },
          {
            comName: 'YkcInput',
            key: 'parkNum',
            label: '车位号',
            placeholder: '请输入车位号',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '归属电站',
            placeholder: '请选择归属电站',
            data: this.brandListData,
          },
          {
            comName: 'YkcDropdown',
            key: 'stationOperator',
            label: '归属商户',
            placeholder: '请选择归属商户',
            data: this.OperatorListData,
          },
          {
            multiple: true,
            clearable: true,
            comName: 'YkcDropdown',
            key: 'statusList',
            label: '工作状态',
            placeholder: '请选择工作状态',
            data: [
              { id: '0', name: '离线' },
              { id: '1', name: '故障' },
              { id: '2', name: '空闲' },
              { id: '3', name: '充电中' },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'startStatus',
            label: '启停状态',
            placeholder: '请选择启停状态',
            data: [
              all,
              ...[
                { id: '0', name: '停用' },
                { id: '1', name: '启用' },
              ],
            ],
          },
          {
            remote: true,
            clearable: true,
            comName: 'YkcDropdown',
            key: 'brandId',
            label: '终端型号',
            placeholder: '请选择终端型号',
            data: this.brandAndModelData,
          },
          {
            comName: 'YkcDropdown',
            key: 'markStatus',
            label: '二维码下发',
            placeholder: '请选择二维码下发',
            data: [
              all,
              ...[
                { id: 0, name: '未下发' },
                { id: 1, name: '下发成功' },
                { id: 2, name: '下发中' },
                { id: 3, name: '下发失败' },
              ],
            ],
          },
        ];
      },
      drawerTitle() {
        let txt = '';
        if (this.drawerType === 'add') {
          txt = `社区私桩新增`;
        } else if (this.drawerType === 'edit') {
          txt = `编辑终端`;
        } else if (this.drawerType === 'detail') {
          txt = `社区私桩详情`;
        }
        return txt;
      },
    },
    methods: {
      code,
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('asset:station:privatepile:edit'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  console.log('row++++', row);
                  this.isEdit = true;
                  this.showDrawer = true;
                  this.gunId = row.gunId;
                  // this.parName = row.gunName;
                  // this.parCode = row.gunCode;
                  this.editInfo = row;
                  // this.maintainDate.startTime = row.maintenanceStartDate;
                  // this.maintainDate.endTime = row.maintenanceEndDate;
                  this.drawerType = 'edit';
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:privatepile:detail'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  console.log('row++++', row);
                  this.isEdit = true;
                  this.showDrawer = true;
                  this.gunId = row.gunId;
                  this.editInfo = row;
                  // this.parName = row.gunName;
                  // this.parCode = row.gunCode;
                  // this.maintainDate.startTime = row.maintenanceStartDate;
                  // this.maintainDate.endTime = row.maintenanceEndDate;
                  this.drawerType = 'detail';
                }}>
                详情
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:privatepile:enable'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 1}
                onClick={() => {
                  this.doAbleActivity(row);
                }}>
                启用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:privatepile:enable'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.startStatus === 0}
                onClick={() => {
                  this.doDisableActivity(row);
                }}>
                停用
              </ykc-button>
            ),
          },
          {
            // enabled: () => code('asset:station:privatepile:Timing'),
            enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.doQrcode(row);
                }}>
                查看二维码
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:privatepile:Timing'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.doTimingAndPricing(row);
                }}>
                校时/校价
              </ykc-button>
            ),
          },

          {
            enabled: () => code('asset:station:privatepile:updatestation'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  // this.doTimingAndPricing(row);
                  console.log('row====', row);
                  this.gunId = row.gunId;
                  this.ruleForm = {
                    stationId: row.stationId,
                    parkNum: row.parkNum,
                  };
                  this.showDialog = true;
                }}>
                迁桩
              </ykc-button>
            ),
          },

          {
            enabled: () => code('asset:station:privatepile:del'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.doDelete(row);
                }}>
                删除
              </ykc-button>
            ),
          },
          {
            enabled: () => code('asset:station:privatepile:updatestation'),
            // enabled: () => true,
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.editInfo = row;
                  this.changeForm.pileId = null;
                  this.showchangeDialog = true;
                }}>
                换桩
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 批量处理
       * */
      handleCommand(command) {
        if (command === 'levelConfig') {
          this.drawerType = 'import';
          this.showDrawer = true;
        } else if (command === 'growthValueRule') {
          // 校时/校价
          console.log('P2需求', this.selectedRows);
          if (this.selectedRows.length > 0) {
            const params = this.selectedRows.map(item => item.gunCode);
            YkcDialog({
              showTitle: false,
              showFooter: true,
              dialogType: 'feedback',
              desc: '确认批量校时/校价吗？',
              onCancel: done => {
                console.log(done);
                done();
              },
              onConfirm: done => {
                console.log(done);
                directPage
                  .setTimeAndPrice({ gunCodes: params, isPrivatePile: 1 })
                  .then(res => {
                    console.log(res);
                    this.$message.success('批量校时/校价成功');
                    this.requestList();
                  })
                  .catch(err => {
                    console.log(err);
                  });
                done();
              },
            });
          } else {
            this.$message.warning('请选择数据');
          }
        } else if (command === 'publishQrCode') {
          if (this.selectedRows.length > 0) {
            const params = this.selectedRows.map(item => item.gunId);
            YkcDialog({
              showTitle: false,
              showFooter: true,
              dialogType: 'feedback',
              desc: '确认对于选中的终端进行二维码下发？',
              onCancel: done => {
                done();
              },
              onConfirm: done => {
                directPage
                  .publishQrcode({ gunIds: params, isPrivatePile: 1 })
                  .then(() => {
                    this.$message.success('下发成功');
                    this.requestList();
                  })
                  .catch(err => {
                    console.log(err);
                  });
                done();
              },
            });
          } else {
            this.$message.warning('请选择数据');
          }
        } else if (command === 'exportQrCode') {
          if (this.selectedRows.length > 0) {
            const params = this.selectedRows.map(item => item.gunId);
            offlineExport({
              downloadType: 'gun_mark_export',
              jsonNode: {
                gunIds: params,
              },
            });
          } else {
            this.$message.warning('请选择数据');
          }
        }
      },
      /**
       * 新增
       * */
      add() {
        this.gunId = null;
        this.parName = '';
        this.parCode = '';
        this.drawerType = 'add';
        this.isEdit = false;
        this.showDrawer = true;
      },
      /**
       * @desc 生成二维码
       * */
      doQrcode(row) {
        QRCode.toDataURL(
          `${process.env.VUE_APP_API_URL}/LUOSHU-MP-PAGE/index.html?pNum=${row.gunCode}`
        ).then(url => {
          this.qrcodeImg = url;
          this.showCode = true;
        });
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'station_private_pile_export',
            jsonNode: {
              stationType: 1,
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 单个启用
       * */
      doAbleActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认启用该电桩？',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            directPage
              .dirStartSwitch({ gunIdList: [row.gunId], startStatus: 1, isPrivatePile: 1 })
              .then(res => {
                console.log(res);
                this.$message.success('电桩启用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 单个停用
       * */
      doDisableActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '点击确认将停用该电桩',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            directPage
              .dirStartSwitch({ gunIdList: [row.gunId], startStatus: 0, isPrivatePile: 1 })
              .then(res => {
                console.log(res);
                this.$message.success('电桩停用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 获取勾选id
       * */
      selectionChange(selection) {
        this.selectedRows = [...selection];
      },
      /**
       * @desc 单个校时/校价
       * */
      doTimingAndPricing(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认对该电桩校时/校价吗？',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            directPage
              .setTimeAndPrice({ gunCode: row.gunCode })
              .then(res => {
                console.log(res);
                this.$message.success('校时/校价成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * @desc 单个删除
       * */
      doDelete(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '终端删除会连当前电桩下的终端一并删除，删除后不可恢复!',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            directPage
              .dirDelete({ gunId: row.gunId, isPrivatePile: 1 })
              .then(res => {
                console.log(res);
                this.$message.success('电桩删除成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.show1 = true;
      },
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.customForm.checkOption = e;
        console.log('this.customForm+++', this.customForm);
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.customForm);
        this.$refs.customForm.validate(valid => {
          if (valid) {
            localSetItem('privateCustomForm', JSON.stringify(this.customForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            done(); // 关闭弹框
            drawerDone(); // 关闭抽屉
          },
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.show1 = false;
      },
      /**
       * @desc 关闭抽屉
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 获取充电站列表
       */
      requestStationList(name) {
        omManagementApi
          .findStationInfoLikeName({ name })
          .then(res => {
            console.log('获取归属电站列表+++++', res);
            // const params = res;
            // params.map(item => {
            //   return this.brandListData.push({
            //     id: item.stationId,
            //     name: item.stationName,
            //   });
            // });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属电站列表
       */
      requestBrandList() {
        barrierPage
          .findPriOrPubStations({ type: 1, isPrivatePile: 1 })
          .then(res => {
            console.log('获取归属电站列表+++++', res);
            const params = res;
            params.map(item => {
              return this.brandListData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        directPage
          .userOperatorList({ operatorType: '1' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取品牌型号列表
       */
      requestBrandAndModelList() {
        brandModelPage
          .brandModelList({ keyword: '' })
          .then(res => {
            console.log('获取品牌型号列表+++++', res);
            res.map(item => {
              return this.brandAndModelData.push({
                id: item.id,
                name: item.modelName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取桩编码列表
       */
      requestPileCodeList() {
        directPage
          .queryPile({ pileCode: '' })
          .then(res => {
            this.gunCodeData = [];
            res.map(item => {
              return this.gunCodeData.push({
                id: item.pileId,
                name: item.pileCode,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        directPage
          .privateFindByPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 换桩
       */
      stationChange() {
        this.$refs.changeForm.validate(valid => {
          if (valid) {
            const data = {
              oldPileId: this.editInfo.stationPileContractVO.pileId,
              newPileId: this.changeForm.pileId,
            };
            directPage
              .changePile(data)
              .then(res => {
                this.showchangeDialog = false;
                this.requestList();
                this.requestPileCodeList();
                if (res) {
                  this.$message.success('换桩成功');
                } else {
                  this.$message.error('换桩失败');
                }
              })
              .catch(err => {
                console.log(err);
              });
          }
        });
      },
      /**
       * 迁桩
       */
      updateStation() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const data = {
              id: this.gunId,
              parkNum: this.ruleForm.parkNum,
              stationId: this.ruleForm.stationId,
              isPrivatePile: 1,
            };
            directPage
              .checkParkNum(data)
              .then(res => {
                YkcDialog({
                  dialogType: 'feedback',
                  showTitle: false,
                  showFooter: true,
                  desc: res
                    ? '本车位当前已存在桩，是否确认替换此桩?'
                    : '本车位当前无桩，可放心迁桩',
                  onCancel: done => {
                    // this.showDialog = false;
                    done();
                  },
                  onConfirm: done => {
                    directPage
                      .dirUpdate(data)
                      .then(() => {
                        this.$message.success('迁桩成功');
                        this.requestList();
                      })
                      .catch(err => {
                        console.log(err);
                        this.$message.error('迁桩失败，请稍后重试');
                      })
                      .finally(() => {
                        done();
                        this.showDialog = false;
                      });
                  },
                });
              })
              .catch(err => {
                console.log(err);
              });
          }
        });
      },
      /**
       *
       */
      dealData(data) {
        return data || '——';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }
</style>
